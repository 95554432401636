import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'; /* Translations */
import i18n from 'i18next';                     /* Translations - RTL handling */


import flutterImg from '../images/flutter.png';
import flutterIcon from '../icons/flutter.svg'
import fflowIcon from '../icons/flutterflow.svg'
import rnativeIcon from '../icons/reactnative.svg'
import xamarinIcon from '../icons/xamarin.svg'
import reactImg from '../images/reactnative.png'
import xamarinImg from '../images/xamarin.png'


const CrossPlatformInfo = () => {


    useDocTitle('CodeSuits | Cross Platform Development')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const { t } = useTranslation()


    const {


        tech_cross_title,
        tech_cross_subtitle1,
        tech_cross_subtitle2,
        tech_cross_subtitle3,

        tech_cross_brief_flutter_title,
        tech_cross_brief_flutter_description,

        tech_cross_brief_react_title,
        tech_cross_brief_react_description,

        tech_cross_brief_xamarine_title,
        tech_cross_brief_xamarine_description,

        tech_cross_flutter_title,
        tech_cross_flutter_description,

        tech_cross_react_title,
        tech_cross_react_description,

        tech_cross_xamarine_title,
        tech_cross_xamarine_description1,
        tech_cross_xamarine_description2,

        tech_cross_talk,

    } = t("tech_pages.tech_cross");


    const { ux_techstack,
        uxui_stack,
        ux_techstack1,
        ux_techstack2,
        ux_techstack3,
        ux_techstack4,
        ux_techstack5, } = t("portfolio_all.pages.ux");

    const { ui_techstack,
        ui_techstack1,
        ui_techstack2,
        ui_techstack3,
        ui_techstack4,
        ui_techstack5, } = t("portfolio_all.pages.ui");



    useEffect(() => {

        window.scrollTo(0, 0);
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);


    return (
        <>
            <div>
                <NavBar />
            </div>


            <section class="py-10 bg-gray-100 sm:py-16 lg:py-24  ">

                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl ">{tech_cross_title}</h2>
                    </div>

                    <div class="max-w-2xl mx-auto text-center">
                        <p class="max-w-2xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b> {tech_cross_subtitle1}</b> </p>
                        <div class="mt-5">
                            <p className=' mt-8 text-base text-gray-600'> {tech_cross_subtitle2}</p>
                        </div>
                    </div>
                    <div class="max-w-2xl mx-auto text-center">
                        <p class="max-w-xl mx-auto mt-4 text-xl leading-relaxed text-gray-600"><b>{tech_cross_subtitle3}</b></p>
                    </div>
                </div>

                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">
                    <div className="w-full bg-white p-8  md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-3  w-full gap-x-12 gap-y-6">

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                <div class="px-8 py-12">
                                    <img class="flex justify-center mx-auto " src={flutterImg} alt="" />
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_cross_brief_flutter_title} </p>
                                    <p class="mt-1 text-base text-gray-600"> {tech_cross_brief_flutter_description}</p>
                                </div>
                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={reactImg} alt="" />
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_cross_brief_react_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_cross_brief_react_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={xamarinImg} alt="" />
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_cross_brief_xamarine_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_cross_brief_xamarine_description}</p>
                                </div>
                            </div>
                        </div>



                        <section class="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">
                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                                <div class="max-w-2xl mx-auto text-center">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_cross_flutter_title}</h4>
                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_cross_flutter_description}</p>
                                </div>


                                <div className='flex justify-center items-center gap-8 mt-12' data-aos="zoom-in" data-aos-delay="500">
                                    <div className='flex-col justify-center items-center'>
                                        <div className='flex  justify-center items-center'>
                                            <img alt="" className="rounded-t float-center" src={flutterIcon} style={{ height: 42, width: 42 }} />
                                        </div>


                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            Flutter</h3>
                                    </div>


                                    <div className='flex-col justify-center items-center'>

                                        <div className='flex  justify-center items-center'>
                                            <img alt="" className="rounded-t float-center" src={fflowIcon} style={{ height: 42, width: 42 }} />
                                        </div>


                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            FlutterFlow</h3>
                                    </div>
                                </div>

                            </div>

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_cross_react_title} </h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_cross_react_description}</p>
                                </div>


                                <div className='flex justify-center items-center gap-8 mt-12' data-aos="zoom-in" data-aos-delay="500">

                                    <div className='flex-col justify-center items-center'>

                                        <div className='flex  justify-center items-center'>
                                            <img alt="" className="rounded-t float-center" src={rnativeIcon} style={{ height: 42, width: 42 }} />
                                        </div>


                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            React Native</h3>
                                    </div>
                                </div>
                            </div>


                            {/* ---  Xamarin --- */}

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_cross_xamarine_title} </h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_cross_xamarine_description1} <br /></p>
                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_cross_xamarine_description2}</p>
                                </div>
                            </div>



                            {/* ---  UX Tech Stack --- */}

                            <div className='flex justify-center items-center gap-8 mt-12' data-aos="zoom-in" data-aos-delay="500">

                                <div className='flex-col justify-center items-center'>

                                    <div className='flex  justify-center items-center'>
                                        <img alt="" className="rounded-t float-center" src={xamarinIcon} style={{ height: 42, width: 42 }} />
                                    </div>


                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                        Xamarin </h3>
                                </div>
                            </div>

                            <div class="max-w-2xl mx-auto text-center lg:pt-12">

                                <Link to="/contact" class="inline-flex items-center justify-center px-8 py-4 text-base font-semibold text-blue-900 transition-all duration-200 border-2 border-blue-900 rounded-md mt-9 hover:bg-blue-900 hover:text-white hover:border-blue-900 focus:bg-gray-900 focus:text-white focus:border-gray-900">
                                    {tech_cross_talk}
                                </Link>
                            </div>
                        </section>
                    </div >
                </div >
            </section >
            <Footer />
        </>


    )
}
export default CrossPlatformInfo;